import { useRecoilValue } from "recoil";
import { userState } from "../../atoms/user";
import { Accounts } from "./accounts";
import { Actions } from "./actions";
import { Add } from "./actions/add";
import { Spinner } from "./spinner";

const Body = () => {
  const user = useRecoilValue(userState);

  return (
    <div>
      {user === null ? (
        "No User Found"
      ) : (
        <div>
          <div className="grid grid-cols-4 bg-gray-100 w-full mt-2 px-2 rounded-sm">
            <div className=" col-span-1 flex items-center">
              {user.firstName} {user.lastName}
            </div>

            <div className="col-span-1 flex items-center">{user.email}</div>

            <div className=" col-span-1 flex flex-col justify-center">
              <Accounts accounts={user.Accounts} />
            </div>
            <div>
              <div>
                <Actions accounts={user.Accounts} />
              </div>
            </div>
          </div>
          <div className="w-full py-2 mt-3 flex justify-between">
            <Spinner />
            <Add />
          </div>
        </div>
      )}
    </div>
  );
};

export { Body };
