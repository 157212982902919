import { atom, selector } from "recoil";
import { User } from "shared/interfaces/user";

const usersState = atom<User[]>({
  key: "kycUsersState",
  default: [],
});

export const KycUsers = selector<User[]>({
  key: "kycUsersStateSelector",
  get: ({ get }) => get(usersState),
  set: ({ set }, users) => set(usersState, users),
});
