import { useCallback, useEffect, useRef } from "react";

/**
 * Hook to run a function once every few seconds
 * @param func - Function to execute
 * @param timer - timer to run from
 * @param key- key to watch for
 */
export const useDebounceTime = (
  func: () => void,
  timer: number,
  key: string | undefined
) => {
  const timerRef = useRef(timer);
  const memoizedFunction = useCallback(func, [func, timer, key]);

  useEffect(() => {
    clearTimeout(timerRef.current);

    timerRef.current = window.setTimeout(() => {
      memoizedFunction();
    }, timer);
  }, [timer, memoizedFunction, key]);
};
