import TextField from "@material-ui/core/TextField";
import { useRecoilState } from "recoil";
import { searchTerm } from "../atoms/searchTearm";
import { useGetUser } from "./hooks/getUser";

const Search = () => {
  const [value, setValue] = useRecoilState(searchTerm);
  useGetUser(value);
  return (
    <div className="py-3">
      <TextField
        label="Enter Email"
        placeholder="someone@example.com"
        value={value}
        onChange={(e) => {
          setValue(e.currentTarget.value);
          // Reset users on search
          //setUsers([]);
        }}
      />
    </div>
  );
};

export { Search };
