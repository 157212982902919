import React, { useState } from "react";
import { Button, Switch, FormControlLabel, Modal } from "@material-ui/core";

import { PaymentGateway } from "shared/interfaces/paymentGateway.interface";
import { ConfigurationForm } from "../Form/ConfigurationForm";

import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import { getAuthorization } from "scripts/jwt/getAuthorization";

interface PaymentGatewayActionsProps {
  paymentGateway: PaymentGateway;
  onEdit: () => void;
  reloadGateways: () => void;
}

export const PaymentGatewayActions: React.FC<PaymentGatewayActionsProps> = ({
  paymentGateway,
  onEdit,

  reloadGateways,
}) => {
  const api = process.env.REACT_APP_API;
  const [isConfigOpen, setConfigOpen] = useState(false);

  const handleStatusChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newStatus = event.target.checked ? "active" : "inactive";
    const accessToken = getAuthorization();
    try {
      const response = await fetch(
        `${api}/gateways/status/${paymentGateway.id}`,

        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
          body: JSON.stringify({ status: newStatus }),
        }
      );

      if (response.ok) {
        console.log("Status updated successfully");
        reloadGateways();
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleOpenConfig = () => {
    setConfigOpen(true);
  };

  const handleCloseConfig = () => {
    setConfigOpen(false);
  };
  return (
    <div style={{ display: "flex" }}>
      <Switch
        checked={paymentGateway.status === "active"}
        onChange={handleStatusChange}
        name="status"
        color="primary"
      />
      <Button
        className="p-2 border rounded shadow-md"
        onClick={onEdit}
        style={{ marginRight: "16px" }}
      >
        EDIT
      </Button>
      {/* <FormControlLabel
        label={paymentGateway.status === "active" ? "Active" : "Inactive"}
        control={ */}

      <PermDataSettingIcon
        onClick={handleOpenConfig}
        style={{ cursor: "pointer" }}
      ></PermDataSettingIcon>
      <Modal open={isConfigOpen} onClose={handleCloseConfig}>
        <div
          style={{
            padding: 20,
            backgroundColor: "#fff",
            margin: "15px auto",
            maxWidth: "500px",
          }}
        >
          <ConfigurationForm
            onClose={handleCloseConfig}
            gatewayId={paymentGateway.id}
            paymentGateway={paymentGateway}
          />
        </div>
      </Modal>
    </div>
  );
};
