import produce from "immer";
import { Field } from "shared/interfaces/field";
import validator from "validator";

export interface LoginState {
  email: Field;
  password: Field;
  loading: boolean;
  successMessage: string;
  errorMessage: string;
}

export const initialState: LoginState = {
  email: { value: "", valid: false },
  password: { value: "", valid: false },
  loading: false,
  successMessage: "",
  errorMessage: "",
};

export type Actions =
  | { type: "SetEmail"; data: string }
  | { type: "SetPassword"; data: string }
  | { type: "SetLoading"; data: boolean }
  | { type: "SetSuccessMessage"; data: string }
  | { type: "SetErrorMessage"; data: string };

export const reducer = produce((draft: LoginState, action: Actions) => {
  switch (action.type) {
    case "SetEmail":
      draft.email = {
        value: action.data,
        valid: validator.isEmail(action.data),
      };
      break;

    case "SetPassword":
      draft.password = {
        value: action.data,
        valid: validator.isLength(action.data, { min: 6 }),
      };
      break;

    case "SetLoading":
      draft.loading = action.data;
      break;

    case "SetSuccessMessage":
      draft.successMessage = action.data;

      break;

    case "SetErrorMessage":
      draft.errorMessage = action.data;

      break;
  }
});
