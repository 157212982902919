import { useEffect, useReducer, useRef } from "react";
import { initialState, reducer } from "./reducer";
import { isLoggedIn } from "scripts/jwt/isLoggedIn";
import { useHistory } from "react-router-dom";
import { LoginContext } from "./context";
import { Email } from "./email";
import { Password } from "./password";
import { SuccessMessage } from "./successMessage";
import { ErrorMessage } from "./errorMessage";
import { Submit } from "./submit";

export const LoginPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const loggedIn = useRef(isLoggedIn());

  const router = useHistory();

  useEffect(() => {
    if (loggedIn.current) router.push("/");
  }, [loggedIn, router]);

  return (
    <>
      {loggedIn.current ? null : (
        <div className="w-full flex flex-col">
          <h1 className="text-3xl text-center mt-5">Login</h1>

          <div className="mx-auto mt-5 flex flex-col">
            <LoginContext.Provider value={{ state, dispatch }}>
              <Email />
              <Password />
              <SuccessMessage />
              <ErrorMessage />
              <Submit />
            </LoginContext.Provider>
          </div>
        </div>
      )}
    </>
  );
};
