import { LoginState } from "pages/login/reducer";
import { ValidationResponse } from "shared/interfaces/validationResponse";

export const validateLogin = (data: LoginState): ValidationResponse => {
  if (!data.email.valid) return { status: false, message: "Invalid email" };

  if (!data.password.valid)
    return { status: false, message: "Invalid password" };

  return { status: true, message: "Your data is valid" };
};
