import React, { useContext } from "react";
import { LoginContext } from "../context";

export const SuccessMessage = () => {
  const context = useContext(LoginContext);
  const successMessage = context?.state.successMessage;

  return successMessage && successMessage.length > 0 ? (
    <div className="bg-green-400 text-white p-3 w-full mb-3">
      {successMessage}
    </div>
  ) : null;
};
