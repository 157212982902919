import Spinner from "@material-ui/core/CircularProgress";
import { useRecoilState } from "recoil";
import { kycLoaderState } from "../../atoms/kycLoader";
import { searchTermState } from "../../atoms/searchTerm";
import { KycUsers } from "../../atoms/user";

export const Helpers = () => {
  const loading = useRecoilState(kycLoaderState)[0];
  const searchTerm = useRecoilState(searchTermState)[0];
  const users = useRecoilState(KycUsers)[0];

  return (
    <div>
      {loading ? (
        <div className="p-3 flex">
          <Spinner className="mx-auto" />
        </div>
      ) : null}

      {!loading && searchTerm.length > 0 && users.length === 0 ? (
        <span className="p-3">No users found!</span>
      ) : null}

      {!loading && searchTerm.length === 0 && users.length === 0 ? (
        <span className="p-3">Search for users</span>
      ) : null}
    </div>
  );
};
