import { selector } from "recoil";
import { modalState } from "../atoms/modalStatus";

const editModalState = selector({
  key: "editModalState",
  get: ({ get }) => {
    const state = get(modalState);
    return state.editModal;
  },
  set: ({ set, get }, newValue = false) => {
    const state = get(modalState);
    const val = newValue as boolean;
    set(modalState, { ...state, editModal: val });
  },
});

export { editModalState };
