import { toast } from "react-toastify";

export const notify = (
  status: "success" | "error" | "info",
  message: string
) => {
  switch (status) {
    case "success":
      toast.success(message);
      break;

    case "error":
      toast.error(message);
      break;

    case "info":
      toast.error(message);
  }
};
