import React, { useContext } from "react";
import { LoginContext } from "../context";

export const ErrorMessage = () => {
  const context = useContext(LoginContext);
  const errorMessage = context?.state.errorMessage;

  return errorMessage && errorMessage.length > 0 ? (
    <div className="bg-red-400 text-white p-3 w-full mb-3">{errorMessage}</div>
  ) : null;
};
