import axios, { AxiosError, AxiosResponse } from "axios";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { deleteAccessToken } from "scripts/jwt/deleteJwt";
import { getAuthorization } from "scripts/jwt/getAuthorization";
import { notify } from "scripts/notify";
import { useDebounceTime } from "shared/hooks/useDebounceTime";
import { kycLoaderState } from "../../atoms/kycLoader";
import { KycUsers } from "../../atoms/user";

/**
 * @description - Gets new users every second
 * @param searchTerm - Search Term for users
 */
export const useGetUsers = (searchTerm: string) => {
  const setLoading = useRecoilState(kycLoaderState)[1];
  const router = useHistory();

  const setUsers = useRecoilState(KycUsers)[1];

  useEffect(() => {
    if (searchTerm.length > 0) setLoading(true);
  }, [searchTerm, setLoading]);

  const fetchUsers = useCallback(async () => {
    if (searchTerm.length === 0) return;

    const api = process.env.REACT_APP_API;

    let response: AxiosResponse | null = null;

    try {
      response = await axios.get(`${api}/kyc`, {
        headers: {
          Authorization: getAuthorization(),
        },
        params: { query: searchTerm },
      });
    } catch (e) {
      const error = e as AxiosError;

      setLoading(false);

      if (error.response && error.response.status === 403) {
        deleteAccessToken();
        router.push("/login");
        return notify("error", "Session Timed out!");
      }
    }

    setLoading(false);
    if (response?.data) setUsers(response.data);
  }, [setUsers, router, searchTerm, setLoading]);

  useDebounceTime(fetchUsers, 1000, searchTerm);
};
