import { atom } from "recoil";

const modalState = atom({
  key: "modalState",
  default: {
    addModal: false,
    editModal: false,
  },
});

export { modalState };
