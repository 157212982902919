import { User } from "shared/interfaces/user";
import styles from "../../styles/table.module.scss";

export const Verified = (props: { user: User }) => {
  return (
    <td className={styles.tableCell}>{`${
      props.user.kycVerification ? "Verified" : "Un-Verified"
    }`}</td>
  );
};
