import { IconButton } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import axios, { AxiosError } from "axios";
import { userState } from "pages/account/content/atoms/user";
import { useRecoilState } from "recoil";
import { User } from "shared/interfaces/user";
import Tooltip from "@material-ui/core/Tooltip";
import { notify } from "scripts/notify";
import { getAuthorization } from "scripts/jwt/getAuthorization";

const Remove = ({ accountId }: { accountId: string }) => {
  const [currentUser, setUser] = useRecoilState(userState);
  const removeAccount = async () => {
    if (!currentUser) {
      return;
    }

    const userId = currentUser.id;
    const api = process.env.REACT_APP_API;

    try {
      const response = await axios.delete(`${api}/account`, {
        data: { userId: userId, accountId: accountId },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      const { status, data } = response;
      if (status === 200) {
        notify("success", data);
      }
    } catch (error) {
      const e = error as AxiosError;
      if (!e.isAxiosError) {
        notify("error", "Something Went Wrong!");
        return;
      }

      const response = e.response;
      if (response?.status === 403) {
        notify("error", response.data.message);
        return;
      }
      //for general exceptions
      notify("error", "Something Went Wrong!");
      return;
    }

    //update
    const updatedUser: User = {
      ...currentUser,
      Accounts: [...currentUser.Accounts],
    };
    updatedUser.Accounts = currentUser.Accounts.filter((acc) => {
      return acc.id !== accountId;
    });
    setUser(updatedUser);
  };
  return (
    <div className="flex bg-gray-400 rounded-md">
      <Tooltip title="Remove">
        <IconButton onClick={removeAccount}>
          <RemoveIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export { Remove };
