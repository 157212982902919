import { Button, Modal } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import axios, { AxiosError } from "axios";
import { userState } from "pages/account/content/atoms/user";
import { ChangeEvent, MouseEvent } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { notify } from "scripts/notify";
import { User } from "shared/interfaces/user";
import { accountNumber } from "../../atoms/accountNumber";

import { validationError } from "../../atoms/validationError";
import { getAuthorization } from "scripts/jwt/getAuthorization";
import { Spinner } from "../../../spinner";
import { loadingState } from "pages/account/content/atoms/loadingState";
import { accountNumberSchema } from "../../schema/accountNumber.schema";

const AddModal = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const [value, setValue] = useRecoilState(accountNumber);
  const [user, setUser] = useRecoilState(userState);
  const [error, setError] = useRecoilState(validationError);
  const setLoading = useSetRecoilState(loadingState);

  const validate = (value: string) => {
    let isValidate = false;
    accountNumberSchema
      .validate({ account: value })
      .then((data) => {
        isValidate = true;
        setError("");
      })
      .catch((err) => {
        isValidate = false;
        setError(err.errors[0]);
      });

    return isValidate;
  };

  const textFieldHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    validate(value);
    setValue(value);
  };

  const handleSubmit: (e: MouseEvent<HTMLButtonElement>) => void = async () => {
    validate(value);
    if (!user || error !== "" || value === "") {
      return;
    }

    setLoading(true);
    const api = process.env.REACT_APP_API;
    let response;
    try {
      response = await axios.post(
        `${api}/account`,
        {
          userId: user.id,
          account: value,
        },
        {
          headers: {
            Authorization: getAuthorization(),
          },
        }
      );

      const { status } = response;
      if (status === 201) {
        notify("success", "Account Created");
      }
    } catch (error) {
      setLoading(false);
      handleClose();
      const e = error as AxiosError;
      if (!e.isAxiosError) {
        notify("error", "Something Went Wrong!");
        return;
      }

      const response = e.response;
      if (response?.status === 403) {
        notify("error", response.data.message);
        return;
      }
      //for general exceptions
      notify("error", "Something Went Wrong!");
      return;
    }
    setLoading(false);
    handleClose();
    const updatedUser: User = { ...user, Accounts: [...user.Accounts] };
    updatedUser.Accounts = response?.data ? response.data : null;
    setUser(updatedUser);
    setValue("");
  };
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="h-full flex">
        <div className="m-auto bg-white p-8">
          <div>
            <TextField
              label="Enter Account Number"
              placeholder="12487854"
              value={value}
              onChange={textFieldHandler}
            />
          </div>
          <div className="text-xs text-red-600">{error && error}</div>
          <div className=" justify-between flex mt-5">
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleSubmit}
            >
              ADD
            </Button>
            <Button variant="contained" size="small" onClick={handleClose}>
              CLOSE
            </Button>
          </div>
          <div>
            <Spinner />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { AddModal };
