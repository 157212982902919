import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";

import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import MaterialTable from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import { usePaymentGateways } from "shared/hooks/usePaymentGateways";
import { PaymentGatewayActions } from "./PaymentGatewayActions/PaymentGatewayActions";
import { PaymentGatewayForm } from "./Form/PaymentGatewayForm";
import { PaymentGateway } from "shared/interfaces/paymentGateway.interface";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const Table = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [statusFilter, setStatusFilter] = useState<string | undefined>(
    undefined
  );
  const [providerFilter, setProviderFilter] = useState<string | undefined>(
    undefined
  );
  const { paymentGateways, totalCount, loadPaymentGateways } =
    usePaymentGateways();

  const [editingGateway, setEditingGateway] = useState<PaymentGateway | null>(
    null
  );
  const [open, setOpen] = useState(false);

  const handleOpen = (gateway: PaymentGateway | null = null) => {
    setEditingGateway(gateway);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingGateway(null);
  };
  // Utility function to format date
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    console.log(totalCount);
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleStatusFilterChange = (event: ChangeEvent<{ value: unknown }>) => {
    setStatusFilter(event.target.value as string);
  };

  const handleProviderFilterChange = (
    event: ChangeEvent<{ value: unknown }>
  ) => {
    setProviderFilter(event.target.value as string);
  };

  useEffect(() => {
    loadPaymentGateways({
      page,
      limit: rowsPerPage,
      status: statusFilter,
      provider: providerFilter,
    });
  }, [page, rowsPerPage, statusFilter, providerFilter, loadPaymentGateways]);

  return (
    <div className="pt-16">
      <div className="flex justify-start">
        <div style={{ marginRight: "16px" }}>
          <label style={{ marginRight: "12px" }}>Status:</label>
          <Select
            value={statusFilter || ""}
            onChange={handleStatusFilterChange}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Status
            </MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </Select>
        </div>
        <div>
          <label style={{ marginRight: "12px" }}>Provider:</label>
          <Select
            value={providerFilter || ""}
            onChange={handleProviderFilterChange}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Provider
            </MenuItem>
            <MenuItem value="Stripe">Stripe</MenuItem>
            <MenuItem value="Cashfree">Cashfree</MenuItem>
            <MenuItem value="Razorpay">Razorpay</MenuItem>
            <MenuItem value="Zaakpay">Zaakpay</MenuItem>
            <MenuItem value="Utrust">Utrust</MenuItem>
            <MenuItem value="Paypal">Paypal</MenuItem>
            <MenuItem value="Sabpaisa">Sabpaisa</MenuItem>
            <MenuItem value="UPI">UPI</MenuItem>
          </Select>
        </div>
      </div>

      <div className="flex justify-end">
        <Button
          className="p-3 border rounded shadow-md"
          onClick={() => handleOpen()}
        >
          ADD
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <PaymentGatewayForm
              gateway={editingGateway}
              onClose={handleClose}
              loadPaymentGateways={() =>
                loadPaymentGateways({
                  page,
                  limit: rowsPerPage,
                  status: statusFilter,
                  provider: providerFilter,
                })
              }
            />
          </Box>
        </Modal>
      </div>

      <TableContainer component={Paper}>
        <MaterialTable>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Provider</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Updated At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {paymentGateways?.map((paymentGateway) => (
              <TableRow key={paymentGateway.id}>
                <TableCell>{paymentGateway.code}</TableCell>
                <TableCell>{paymentGateway.name}</TableCell>
                <TableCell>{paymentGateway.gatewayProvider}</TableCell>
                <TableCell>{paymentGateway.description}</TableCell>
                <TableCell>{paymentGateway.status}</TableCell>
                <TableCell>{formatDate(paymentGateway.createdDate)}</TableCell>
                <TableCell>{formatDate(paymentGateway.updatedAt)}</TableCell>
                <TableCell>
                  <PaymentGatewayActions
                    paymentGateway={paymentGateway}
                    onEdit={() => handleOpen(paymentGateway)}
                    reloadGateways={() =>
                      loadPaymentGateways({
                        page,
                        limit: rowsPerPage,
                        status: statusFilter,
                        provider: providerFilter,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
            {rowsPerPage > 0 &&
              paymentGateways?.filter(
                (paymentGateway) =>
                  (statusFilter === "All" ||
                    paymentGateway.status === statusFilter) &&
                  (providerFilter === "All" ||
                    paymentGateway.gatewayProvider === providerFilter)
              ).length === 0 &&
              !paymentGateways && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No data found
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </MaterialTable>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};
