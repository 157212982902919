import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { getAuthorization } from "scripts/jwt/getAuthorization";
import { PaymentGateway } from "shared/interfaces/paymentGateway.interface";
interface Configuration {
  version: number;
  apiUrl: string;
  apiKey: string;
  apiSecretKey: string;
  returnUrl: string;
  proxyApi: string;
  payerEmail: string;
  clientCode: string;
  userName: string;
  password: string;
  gatewayId?: number;
  qrImage?: string;
}

interface ConfigurationFormProps {
  configuration?: Configuration | null;
  gatewayId: number;
  onClose: () => void;
  paymentGateway: PaymentGateway;
}
export const ConfigurationForm: React.FC<ConfigurationFormProps> = ({
  configuration,
  gatewayId,
  onClose,
  paymentGateway,
}) => {
  const [formData, setFormData] = useState<Configuration>({
    version: 0,
    apiUrl: "",
    apiKey: "",
    apiSecretKey: "",
    returnUrl: "",
    proxyApi: "",
    payerEmail: "",
    clientCode: "",
    userName: "",
    password: "",
    qrImage: "",
  });
  // const [fileError, setFileError] = useState<string | null>(null);
  const api = process.env.REACT_APP_API;
  const accessToken = getAuthorization();
  useEffect(() => {
    const fetchConfiguration = async () => {
      try {
        const response = await fetch(
          `${api}/gateways/configuration/${gatewayId}`,
          {
            headers: {
              authorization: accessToken,
            },
          }
        );

        if (response.ok) {
          const config = await response.json();

          setFormData(
            config.data || {
              version: 0,
              apiUrl: "",
              apiKey: "",
              apiSecretKey: "",
              returnUrl: "",
              proxyApi: "",
              payerEmail: "",
              clientCode: "",
              userName: "",
              password: "",
              imageFile: null,
              qrImage: "",
            }
          );
        } else {
          console.error("Error loading configuration:", response.statusText);
        }
      } catch (error) {
        console.error("Error loading configuration:", error);
      }
    };

    if (gatewayId) {
      fetchConfiguration();
    }
  }, [gatewayId, api]);

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value, files } = e.target;
  //   if (name === "imageFile" && files) {
  //     const file = files[0];
  //     if (file.size > 2 * 1024 * 1024) {
  //       setFileError("File size should not exceed 2MB");
  //     } else {
  //       setFileError(null);
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         setFormData((prevData) => ({
  //           ...prevData,
  //           qrImage: reader.result as string,
  //         }));
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   } else {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       [name]: name === "version" ? Number(value) : value,
  //     }));
  //   }
  // };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "version" ? Number(value) : value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const apiUrl = `${api}/gateways/configuration/save`;
    const method = "PUT";
    const payload = {
      ...formData,
      gatewayId,
    };

    try {
      const response = await fetch(apiUrl, {
        method,
        headers: {
          "Content-Type": "application/json",
          authorization: accessToken,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Success:", data);
        onClose();
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        style={{ marginBottom: "16px" }}
      >
        {configuration ? "Edit configuration" : "Add configuration"}
      </Typography>
      {paymentGateway.gatewayProvider === "UPI" ? (
        <>
          <TextField
            label="Enter Client Code"
            type="text"
            variant="outlined"
            size="small"
            name="clientCode"
            value={formData.clientCode}
            onChange={handleChange}
            style={{ marginBottom: "16px", width: "100%" }}
          ></TextField>
          {/* <FormControl
            error={!!fileError}
            style={{ marginBottom: "16px", width: "100%" }}
          >
            <InputLabel style={{ marginTop: "-30px" }} htmlFor="imageFile">
              QR Code
            </InputLabel>
            <input
              id="imageFile"
              name="imageFile"
              type="file"
              accept="image/*"
              onChange={handleChange}
              style={{ marginTop: "16px" }}
            />
            {fileError && <FormHelperText>{fileError}</FormHelperText>}
          </FormControl> */}
          <TextField
            label="Enter QR Code"
            type="text"
            variant="outlined"
            size="small"
            name="qrImage"
            value={formData.qrImage}
            onChange={handleChange}
            style={{ marginBottom: "16px", width: "100%" }}
          ></TextField>
        </>
      ) : (
        <>
          <TextField
            label="Enter Version"
            type="text"
            variant="outlined"
            size="small"
            name="version"
            value={formData.version}
            onChange={handleChange}
            style={{ marginBottom: "16px", width: "100%" }}
          ></TextField>
          <TextField
            label="Enter ApiUrl"
            type="text"
            variant="outlined"
            size="small"
            name="apiUrl"
            value={formData.apiUrl}
            onChange={handleChange}
            style={{ marginBottom: "16px", width: "100%" }}
          ></TextField>
          <TextField
            label="Enter ApiKey"
            type="text"
            variant="outlined"
            size="small"
            name="apiKey"
            value={formData.apiKey}
            onChange={handleChange}
            style={{ marginBottom: "16px", width: "100%" }}
          ></TextField>
          <TextField
            label="Enter ApiSecretKey"
            type="text"
            variant="outlined"
            size="small"
            name="apiSecretKey"
            value={formData.apiSecretKey}
            onChange={handleChange}
            style={{ marginBottom: "16px", width: "100%" }}
          ></TextField>
          <TextField
            label="Enter Return Url"
            type="text"
            variant="outlined"
            size="small"
            name="returnUrl"
            value={formData.returnUrl}
            onChange={handleChange}
            style={{ marginBottom: "16px", width: "100%" }}
          ></TextField>
          <TextField
            label="Enter ProxyApi"
            type="text"
            variant="outlined"
            size="small"
            name="proxyApi"
            value={formData.proxyApi}
            onChange={handleChange}
            style={{ marginBottom: "16px", width: "100%" }}
          ></TextField>
          <TextField
            label="Enter PayerEmail"
            type="text"
            variant="outlined"
            size="small"
            name="payerEmail"
            value={formData.payerEmail}
            onChange={handleChange}
            style={{ marginBottom: "16px", width: "100%" }}
          ></TextField>
          <TextField
            label="Enter Client Code"
            type="text"
            variant="outlined"
            size="small"
            name="clientCode"
            value={formData.clientCode}
            onChange={handleChange}
            style={{ marginBottom: "16px", width: "100%" }}
          ></TextField>
          <TextField
            label="Enter UserName"
            type="text"
            variant="outlined"
            size="small"
            name="userName"
            value={formData.userName}
            onChange={handleChange}
            style={{ marginBottom: "16px", width: "100%" }}
          ></TextField>
          <TextField
            label="Enter Password"
            type="text"
            variant="outlined"
            size="small"
            name="password"
            value={formData.password}
            onChange={handleChange}
            style={{ marginBottom: "16px", width: "100%" }}
          ></TextField>
        </>
      )}
      <div>
        <Button
          type="submit"
          style={{
            backgroundColor: "#1976d2",
            color: "#fff",
            border: "2px solid #1976d2",
            padding: "8px 16px",
          }}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};
