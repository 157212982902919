import { Button } from "@material-ui/core";
import { useRecoilState, useSetRecoilState } from "recoil";
import { accountNumber } from "../atoms/accountNumber";
import { validationError } from "../atoms/validationError";
import { addModalState } from "../selector/addModalState";

import { AddModal } from "./modal";

const Add = () => {
  const [isOpen, setIsOpen] = useRecoilState(addModalState);
  const setError = useSetRecoilState(validationError);
  const setValue = useSetRecoilState(accountNumber);

  const handleClose = () => {
    setValue("");
    setError("");
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };
  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add
      </Button>

      <AddModal isOpen={isOpen as boolean} handleClose={handleClose} />
    </div>
  );
};

export { Add };
