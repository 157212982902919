import jwt from "jwt-decode";

export const decodeJwt = () => {
  if (typeof localStorage === "undefined") return false;

  const accessToken = localStorage.getItem("access_token");

  if (!accessToken) return false;

  let decodedToken: {
    email: string;
    role: string;
    iat: string;
    exp: string;
  };

  try {
    decodedToken = jwt(accessToken);
  } catch (e) {
    return false;
  }

  return decodedToken;
};
