import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";

import { AccountPage } from "pages/account";
import { KycPage } from "./pages/kyc";
import { LoginPage } from "./pages/login";
import { Payments as PaymentsPage } from "pages/Payments/Payments";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import store from "redux/store";
import { PaymentGateways as PaymentGatewaysPage } from "pages/paymentGateways/paymentGateways";
import { useEffect, useRef } from "react";
import { isLoggedIn } from "scripts/jwt/isLoggedIn";

function App() {
  const router = useHistory();
  const loggedIn = useRef(isLoggedIn());
  useEffect(() => {
    if (!loggedIn.current) router?.push("/");
  }, [router]);
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/payments">
              <PaymentsPage />
            </Route>
            <Route path="/paymentGateways">
              <PaymentGatewaysPage />
            </Route>
            <Route path="/account">
              <AccountPage />
            </Route>
            <Route path="/">
              <KycPage />
            </Route>
          </Switch>
        </BrowserRouter>
      </Provider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
