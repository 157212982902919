import axios, { AxiosError, AxiosResponse } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";

import { Payment } from "shared/interfaces/payment.interface";
import { getAuthorization } from "scripts/jwt/getAuthorization";
import { notify } from "scripts/notify";

/**
 * @description - Hook used to get payments
 */
export const useGetPayments = (counter: number) => {
  const accessToken = getAuthorization();

  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState<Payment[]>();
  const timer = useRef(0);

  const getPayments = useCallback(async () => {
    if (counter === 0) return;

    setLoading(true);

    const api = process.env.REACT_APP_API;
    let response: AxiosResponse;

    try {
      response = await axios.get(`${api}/payments`, {
        headers: { authorization: accessToken },
      });
    } catch (e) {
      const error = e as AxiosError;

      if (!error.response) {
        return notify("error", "Something went wrong. Try again later!");
      }

      return notify("error", error.response.data);
    }

    setPayments(response.data as Payment[]);
    setLoading(false);
  }, [counter, accessToken]);

  useEffect(() => {
    if (counter === 0) return;
    clearTimeout(timer.current);

    setTimeout(() => {
      getPayments();
    }, 500);
  }, [getPayments, counter]);

  return { loading, payments };
};
