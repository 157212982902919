import { IAccount } from "shared/interfaces/user";
import { Active } from "./active/active";
import { Edit } from "./edit";
import { Remove } from "./remove/remove";

const Actions = ({ accounts }: { accounts: IAccount[] | null }) => {
  return (
    <div>
      {accounts === null ? (
        <div>No account found</div>
      ) : (
        accounts.map((acc, index) => {
          return (
            <div className="flex flex-row  my-1 gap-x-2" key={index}>
              <Remove accountId={acc.id} />
              <Edit accountId={acc.id} value={acc.account} />
              <Active
                accountId={acc.id}
                status={acc.status ? acc.status : "INACTIVE"}
              />
            </div>
          );
        })
      )}
    </div>
  );
};

export { Actions };
