import {
  updateModalState,
  updateSelectedPaymentId,
} from "pages/Payments/PaymentsSlice/PaymentsSlice";

import { Payment } from "shared/interfaces/payment.interface";
import { useAppDispatch } from "redux/hooks";

export const PaymentActions = (props: { payment: Payment }) => {
  const {
    payment: { newStatus, paymentId },
  } = props;

  const dispatch = useAppDispatch();

  const openModal = () => {
    dispatch(updateSelectedPaymentId(paymentId));
    dispatch(updateModalState("open"));
  };

  return newStatus === "Pending" ? (
    <div>
      <button className="p-3 border rounded shadow-md" onClick={openModal}>
        Change Status
      </button>
    </div>
  ) : (
    <></>
  );
};
