import { IAccount } from "shared/interfaces/user";

const Accounts = ({ accounts }: { accounts: IAccount[] | null }) => {
  return accounts === null ? (
    <div>No Account</div>
  ) : (
    <>
      {accounts.map((acc, index) => {
        return (
          <div className=" h-full flex items-center" key={index}>
            {acc.account}
          </div>
        );
      })}
    </>
  );
};

export { Accounts };
