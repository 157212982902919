import { Body } from "./body";
import { Header } from "./header";

const GridView = () => {
  return (
    <div>
      <Header />
      <Body />
    </div>
  );
};

export { GridView };
