import { useRecoilState } from "recoil";
import { KycUsers } from "../../atoms/user";
import { Name } from "./name";
import { Email } from "./email";
import { Verified } from "./verified";
import { Actions } from "./actions";

export const Tbody = () => {
  const [users] = useRecoilState(KycUsers);

  return (
    <tbody>
      {users.map((user, index) => {
        return (
          <tr className="w-full" key={user.id}>
            <Name user={user} />
            <Email user={user} />
            <Verified user={user} />
            <Actions user={user} index={index} />
          </tr>
        );
      })}
    </tbody>
  );
};
