import jwt from "jwt-decode";
import moment from "moment";

export const isLoggedIn = () => {
  if (typeof localStorage === "undefined") return false;

  const accessToken = localStorage.getItem("access_token");

  if (!accessToken) return false;

  let decodedToken: {
    email: string;
    role: string;
    iat: string;
    exp: string;
  };

  try {
    decodedToken = jwt(accessToken);
  } catch (e) {
    return false;
  }

  const tokenExpiry = moment(decodedToken.exp, "X").toISOString();
  const now = moment().toISOString();

  if (moment(tokenExpiry).isBefore(now)) return false;

  return true;
};
