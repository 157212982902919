import { useContext, useRef } from "react";
import Button from "@material-ui/core/Button";
import styles from "./button.module.scss";
import { LoginContext } from "../context";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { validateLogin } from "./utils/validate";
import { notify } from "../../../scripts/notify";
import axios, { AxiosError, AxiosResponse } from "axios";
import { storeJwt } from "../../../scripts/jwt/storeJwt";

export const Submit = () => {
  const context = useContext(LoginContext);
  const timer = useRef(0);
  const router = useHistory();

  const loginUser = async () => {
    if (!context) return;

    const isValid = validateLogin(context?.state);

    if (!isValid.status) {
      context.dispatch({ type: "SetLoading", data: false });
      return notify("error", isValid.message);
    }

    let response: AxiosResponse | null = null;

    try {
      response = await axios.post(
        `${process.env.REACT_APP_API}/auth/admin/login`,
        {
          email: context.state.email.value,
          password: context.state.password.value,
        }
      );
    } catch (e) {
      const error = e as AxiosError;

      context.dispatch({ type: "SetLoading", data: false });

      if (error.response && error.response.status === 403) {
        return notify("error", "Your credentials are invalid");
      }
    }

    if (response?.data) storeJwt(response?.data);

    context.dispatch({ type: "SetLoading", data: false });

    notify("success", "Successfully Logged In!");
    router.push("/");
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      className={styles.button}
      onClick={async () => {
        context?.dispatch({ type: "SetLoading", data: true });
        clearTimeout(timer.current);
        timer.current = window.setTimeout(() => {
          loginUser();
        }, 500);
      }}
    >
      {context?.state.loading ? (
        <CircularProgress size={20} className="mx-auto" />
      ) : (
        <>Login</>
      )}
    </Button>
  );
};
