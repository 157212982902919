import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  getRefreshPayments,
  updateRefreshPayments,
} from "../PaymentsSlice/PaymentsSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useCallback, useEffect, useState } from "react";

import MaterialTable from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import { PaymentActions } from "./PaymentActions/PaymentActions";
import { TableModal } from "./TableModal/TableModal";
import { useGetPayments } from "shared/hooks/useGetPayments";

export const Table = () => {
  const [counter, setCounter] = useState(1);
  const { loading, payments } = useGetPayments(counter);

  const loadPayments = useCallback(() => setCounter(counter + 1), [counter]);
  const refreshPayments = useAppSelector(getRefreshPayments);

  const dispatch = useAppDispatch();
  const disableRefreshPayments = useCallback(
    () => dispatch(updateRefreshPayments(false)),
    [dispatch]
  );

  // If refresh button is clicked, refetch payments
  useEffect(() => {
    if (refreshPayments === false) return;

    loadPayments();
    disableRefreshPayments();
  }, [refreshPayments, disableRefreshPayments, loadPayments]);

  return (
    <div className="pt-16">
      <TableModal />
      <div className="flex justify-end">
        <button className="p-2 border rounded shadow" onClick={loadPayments}>
          {loading ? "Loading..." : "Refresh"}
        </button>
      </div>

      <TableContainer component={Paper}>
        <MaterialTable>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Gateway</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {payments?.map((payment) => (
              <TableRow key={payment.paymentId}>
                <TableCell>{payment.name}</TableCell>
                <TableCell>{payment.email}</TableCell>
                <TableCell>${payment.amount}</TableCell>
                <TableCell>{payment.gateway}</TableCell>
                <TableCell>{payment.newStatus}</TableCell>
                <TableCell>
                  <PaymentActions payment={payment} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MaterialTable>
      </TableContainer>
    </div>
  );
};
