const Header = ({
  headerLabels = ["Name", "Email", "Accounts", "Actions"],
}: {
  headerLabels?: string[];
}) => {
  return (
    <div className="grid grid-cols-4 bg-gray-100 w-full py-1 px-2 font-bold rounded-sm">
      {headerLabels.map((label, index) => {
        return <div key={index}>{label}</div>;
      })}
    </div>
  );
};

export { Header };
