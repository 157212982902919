import { User } from "shared/interfaces/user";
import styles from "../../styles/table.module.scss";

export const Name = (props: { user: User }) => {
  return (
    <td
      className={styles.tableCell}
    >{`${props.user.firstName} ${props.user.lastName}`}</td>
  );
};
