import React, { useContext } from "react";
import styles from "../styles/input.module.scss";
import { LoginContext } from "../context";
import TextField from "@material-ui/core/TextField";

export const Password = () => {
  const context = useContext(LoginContext);

  return (
    <>
      <span className="block pt-3" />
      <TextField
        label="Password"
        type="password"
        className={styles.root}
        defaultValue=""
        onChange={(e) =>
          context?.dispatch({
            type: "SetPassword",
            data: e.currentTarget.value,
          })
        }
      />
      <span className="block pb-3" />
    </>
  );
};
