import CircularProgress from "@material-ui/core/CircularProgress";
import { loadingState } from "pages/account/content/atoms/loadingState";
import { useRecoilValue } from "recoil";

const Spinner = () => {
  const isLoading = useRecoilValue(loadingState);
  return (
    <div>
      {isLoading && (
        <div>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export { Spinner };
