import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { PaymentStatus } from "shared/interfaces/payment-status.interface";
import { RootState } from "redux/store";

interface InitialState {
  refreshPayments: boolean;
  modal: {
    state: "open" | "close";
  };
  selectedPaymentId: string;
  selectedPaymentStatus: PaymentStatus;
}

const initialState: InitialState = {
  refreshPayments: false,
  modal: {
    state: "close",
  },
  selectedPaymentId: "",
  selectedPaymentStatus: "Pending",
};

export const paymentSlice = createSlice({
  name: "Payment Slice",
  initialState,
  reducers: {
    updateModalState(state, action: PayloadAction<"open" | "close">) {
      state.modal.state = action.payload;
    },
    updateSelectedPaymentId(state, action: PayloadAction<string>) {
      state.selectedPaymentId = action.payload;
    },
    resetSelectedPaymentId(state) {
      state.selectedPaymentId = "";
    },
    updateSelectedPaymentStatus(state, action: PayloadAction<PaymentStatus>) {
      state.selectedPaymentStatus = action.payload;
    },
    updateRefreshPayments(state, action: PayloadAction<boolean>) {
      state.refreshPayments = action.payload;
    },
  },
});

export const {
  updateModalState,
  updateSelectedPaymentId,
  updateSelectedPaymentStatus,
  updateRefreshPayments,
} = paymentSlice.actions;

export const modalState = (state: RootState) => state.payments.modal.state;
export const selectedPaymentId = (state: RootState) =>
  state.payments.selectedPaymentId;
export const selectedPaymentStatus = (state: RootState) =>
  state.payments.selectedPaymentStatus;
export const getRefreshPayments = (state: RootState) =>
  state.payments.refreshPayments;

export default paymentSlice.reducer;
