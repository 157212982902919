import * as yup from "yup";

const accountNumberSchema = yup.object({
  account: yup
    .string()
    .required()
    .test("numbers_only_validation", "Only numbers are allowed", (value) => {
      if (!value) return false;
      const reg = new RegExp("^[0-9]+$");
      return reg.test(value);
    })
    .min(2, "Minimum length should be 2")
    .max(20, "Maximum length should be 20"),
});

export { accountNumberSchema };
