import axios, { AxiosError, AxiosResponse } from "axios";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { deleteAccessToken } from "scripts/jwt/deleteJwt";
import { getAuthorization } from "scripts/jwt/getAuthorization";
import { notify } from "scripts/notify";
import { useDebounceTime } from "shared/hooks/useDebounceTime";
import { loadingState } from "../../atoms/loadingState";
import { userState } from "../../atoms/user";

export const useGetUser = (searchTerm: string) => {
  const router = useHistory();
  const setUser = useSetRecoilState(userState);
  const setLoading = useSetRecoilState(loadingState);

  useEffect(() => {
    if (searchTerm.length > 0) setLoading(true);
  }, [searchTerm, setLoading]);

  const fetchUser = useCallback(async () => {
    if (searchTerm.length === 0) return;

    const api = process.env.REACT_APP_API;

    let response: AxiosResponse | null = null;

    try {
      response = await axios.get(`${api}/account`, {
        headers: {
          Authorization: getAuthorization(),
        },
        params: { query: searchTerm },
      });
    } catch (e) {
      const error = e as AxiosError;
      setUser(null);
      if (error.response && error.response.status === 403) {
        deleteAccessToken();
        router.push("/login");
        return notify("error", "Session Timed out!");
      }
      //only display user not found error
      if (
        error.response &&
        error.response.status === 400 &&
        typeof error.response.data.message !== "object"
      ) {
        // const message =
        //   typeof error.response.data.message === "object"
        //     ? error.response.data.message[0]
        //     : error.response.data.message;

        const message = error.response.data.message;
        return notify("error", message);
      }
    }

    setLoading(false);
    if (response?.data) setUser(response.data);
  }, [router, searchTerm, setLoading, setUser]);

  useDebounceTime(fetchUser, 1000, searchTerm);
};
