import axios, { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { getAuthorization } from "scripts/jwt/getAuthorization";
import { notify } from "scripts/notify";
import { PaymentGateway } from "shared/interfaces/paymentGateway.interface";

interface LoadPaymentGatewaysParams {
  page: number;
  limit: number;
  status: string | undefined;
  provider: string | undefined;
}

export const usePaymentGateways = () => {
  const accessToken = getAuthorization();
  const [loading, setLoading] = useState(false);
  const [paymentGateways, setPaymentGateways] = useState<PaymentGateway[]>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const loadPaymentGateways = useCallback(
    async ({ page, limit, status, provider }: LoadPaymentGatewaysParams) => {
      setLoading(true);

      const api = process.env.REACT_APP_API;
      let response;

      try {
        response = await axios.post(
          `${api}/gateways`,
          { page, limit, status, provider },
          { headers: { authorization: accessToken } }
        );
        setPaymentGateways(response.data.data.gateways);
        setTotalCount(response.data.data.total);
      } catch (e) {
        const error = e as AxiosError;

        if (!error.response) {
          notify("error", "Something went wrong. Try again later!");
        } else {
          notify("error", error.response.data);
        }
      }
      setLoading(false);
    },
    [accessToken]
  );

  return { loading, paymentGateways, totalCount, loadPaymentGateways };
};
