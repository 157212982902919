import React, { useContext } from "react";
import TextField from "@material-ui/core/TextField";
import { LoginContext } from "../context";

export const Email = () => {
  const context = useContext(LoginContext);
  const email = context?.state.email.value;

  return (
    <TextField
      label="Email"
      placeholder="someone@example.com"
      type="text"
      value={email}
      onChange={(e) =>
        context?.dispatch({ type: "SetEmail", data: e.currentTarget.value })
      }
    />
  );
};
