import { Search } from "./search";
import { Table } from "./table";

export const Content = () => {
  return (
    <div>
      <Search />
      <Table />
    </div>
  );
};
