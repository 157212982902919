import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { getAuthorization } from "scripts/jwt/getAuthorization";
import { PaymentGateway } from "shared/interfaces/paymentGateway.interface";
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface PaymentGatewayFormProps {
  gateway?: PaymentGateway | null;
  onClose: () => void;
  loadPaymentGateways: () => void;
}
export const PaymentGatewayForm: React.FC<PaymentGatewayFormProps> = ({
  gateway,
  onClose,
  loadPaymentGateways,
}) => {
  const accessToken = getAuthorization();
  const [formData, setFormData] = useState<PaymentGateway>({
    id: 0,
    code: "",
    name: "",
    gatewayProvider: "",
    description: "",
    status: "",
    createdDate: new Date(),
    updatedAt: new Date(),
  });
  const classes = useStyles();
  useEffect(() => {
    if (gateway) {
      setFormData({
        id: gateway.id,
        code: gateway.code,
        name: gateway.name,
        gatewayProvider: gateway.gatewayProvider,
        description: gateway.description,
        status: gateway.status,
        createdDate: new Date(),
        updatedAt: new Date(),
      });
    }
  }, [gateway]);

  const api = process.env.REACT_APP_API;

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const apiUrl = gateway
      ? `${api}/gateways/${gateway.id}`
      : `${api}/gateways/create`;
    const method = gateway ? "PUT" : "POST";

    try {
      const response = await fetch(apiUrl, {
        method,
        headers: {
          authorization: accessToken,
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          code: formData.code,
          name: formData.name,
          provider: formData.gatewayProvider,
          description: formData.description,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Success:", data);
        onClose();
        loadPaymentGateways();
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        style={{ marginBottom: "16px" }}
      >
        {gateway ? "Edit Payment Gateway" : "Add Payment Gateway"}
      </Typography>

      <TextField
        label="Enter Code"
        type="text"
        variant="outlined"
        size="small"
        name="code"
        value={formData.code}
        onChange={handleChange}
        style={{ marginBottom: "16px", width: "100%" }}
        disabled={Boolean(gateway)}
      ></TextField>

      <TextField
        label="Enter Name"
        type="text"
        variant="outlined"
        size="small"
        name="name"
        value={formData.name}
        onChange={handleChange}
        style={{ marginBottom: "16px", width: "100%" }}
      ></TextField>

      <FormControl
        variant="outlined"
        className={classes.formControl}
        size="small"
      >
        <InputLabel id="gatewayProvider">Select Provider</InputLabel>

        <Select
          variant="outlined"
          style={{ marginBottom: "16px" }}
          labelId="gatewayProvider"
          id="gatewayProvider"
          name="gatewayProvider"
          value={formData.gatewayProvider}
          onChange={handleChange}
          disabled={Boolean(gateway)}
        >
          <MenuItem value="Stripe">Stripe</MenuItem>
          <MenuItem value="Cashfree">Cashfree</MenuItem>
          <MenuItem value="Razorpay">Razorpay</MenuItem>
          <MenuItem value="Zaakpay">Zaakpay</MenuItem>
          <MenuItem value="Paynimo">Paynimo</MenuItem>
          <MenuItem value="Utrust">Utrust</MenuItem>
          <MenuItem value="Paypal">Paypal</MenuItem>
          <MenuItem value="Sabpaisa">Sabpaisa</MenuItem>
          <MenuItem value="UPI">UPI</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Enter Description"
        type="text"
        variant="outlined"
        size="small"
        name="description"
        value={formData.description}
        onChange={handleChange}
        style={{ marginBottom: "16px", width: "100%" }}
      ></TextField>
      <div>
        <Button
          type="submit"
          style={{
            backgroundColor: "#1976d2",
            color: "#fff",
            border: "2px solid #1976d2",
            padding: "8px 16px",
          }}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};
