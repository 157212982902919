import { ChangeEvent, useCallback, useState } from "react";
import { MenuItem, Modal, Select } from "@material-ui/core";
import axios, { AxiosError } from "axios";
import {
  modalState,
  selectedPaymentId,
  selectedPaymentStatus,
  updateModalState,
  updateRefreshPayments,
  updateSelectedPaymentStatus,
} from "pages/Payments/PaymentsSlice/PaymentsSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";

import { PaymentStatus } from "shared/interfaces/payment-status.interface";
import { getAuthorization } from "scripts/jwt/getAuthorization";
import { notify } from "scripts/notify";

export const TableModal = () => {
  const dispatch = useAppDispatch();

  const state = useAppSelector(modalState);
  const paymentId = useAppSelector(selectedPaymentId);
  const status = useAppSelector(selectedPaymentStatus);

  const closeModal = useCallback(
    () => dispatch(updateModalState("close")),
    [dispatch]
  );
  const [loading, setLoading] = useState(false);

  const updateStatus = (e: ChangeEvent<{ value: unknown }>) => {
    const status = e.target.value as PaymentStatus;
    dispatch(updateSelectedPaymentStatus(status));
  };

  const updatePayment = useCallback(async () => {
    if (status === "Pending" || loading) return;

    setLoading(true);
    const api = process.env.REACT_APP_API;
    try {
      await axios.put(
        `${api}/payments`,
        {
          paymentId,
          status,
        },
        {
          headers: {
            authorization: getAuthorization(),
          },
        }
      );
    } catch (e) {
      setLoading(false);

      const error = e as AxiosError;

      if (!error.response) {
        console.log(error);
        return notify("error", "Something went wrong. Try again later");
      }

      return notify("error", error.response.data);
    }

    setLoading(false);
    notify("success", "Successfully updated payment");

    closeModal();
    dispatch(updateRefreshPayments(true));
  }, [paymentId, status, loading, closeModal, dispatch]);

  return (
    <Modal open={state === "open" ? true : false} onClose={closeModal}>
      <div className="relative">
        <div
          className="fixed overflow-y-auto bg-white rounded-md"
          style={{
            maxHeight: "300px",
            height: "300px",
            width: "600px",
            top: "30%",
            left: "30%",
          }}
        >
          <h2 className="pt-4 text-xl text-center">Update Status</h2>
          <div className="flex justify-center mt-5">
            <Select
              style={{ width: "200px", marginTop: "30px" }}
              labelId="Select Status"
              value={status}
              onChange={updateStatus}
            >
              <MenuItem disabled value={"Pending"}>
                Pending
              </MenuItem>
              <MenuItem value={"Success"}>Success</MenuItem>
              <MenuItem value={"Failure"}>Failure</MenuItem>
            </Select>
          </div>

          <div className="flex justify-center">
            <button
              className={`px-5 py-2 mt-8 border rounded shadow ${
                status === "Pending" ? "opacity-40 cursor-not-allowed" : ""
              }`}
              onClick={updatePayment}
              disabled={status === "Pending"}
            >
              {loading ? "Loading..." : "Update"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
