import { Search } from "./search";
import { GridView } from "./table";

export const Content = () => {
  return (
    <div className="mx-20">
      <Search />
      <GridView />
    </div>
  );
};
