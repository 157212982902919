import axios, { AxiosError } from "axios";

import Button from "@material-ui/core/Button";
import { KycUsers } from "pages/kyc/content/atoms/user";
import { User } from "shared/interfaces/user";
import { deleteAccessToken } from "scripts/jwt/deleteJwt";
import { getAuthorization } from "scripts/jwt/getAuthorization";
import { notify } from "scripts/notify";
import produce from "immer";
import styles from "../../styles/table.module.scss";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useRef } from "react";

export const Actions = (props: { user: User; index: number }) => {
  const [users, setUsers] = useRecoilState(KycUsers);
  const router = useHistory();

  const timer = useRef(0);

  const changeUserStatus = async (id: number, status: boolean) => {
    clearTimeout(timer.current);

    timer.current = window.setTimeout(async () => {
      const api = process.env.REACT_APP_API;

      try {
        await axios.put(
          `${api}/kyc`,
          { id: users[id].id, status },
          { headers: { Authorization: getAuthorization() } }
        );
      } catch (e) {
        const error = e as AxiosError;

        if (error.response && error.response.status === 403) {
          deleteAccessToken();
          router.push("/login");
          return notify("error", "Session Timed out!");
        }
      }

      setUsers(
        produce((users) => {
          users[id].kycVerification = status;
          return users;
        })
      );
    }, 300);
  };

  return (
    <td className={styles.tableCell}>
      {props.user.kycVerification ? (
        <Button
          variant="contained"
          onClick={() => changeUserStatus(props.index, false)}
        >
          Un-verify
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => changeUserStatus(props.index, true)}
        >
          Verify
        </Button>
      )}
    </td>
  );
};
