import { SideNav } from "shared/sideNav";
import { Table } from "./table/Table";
import { useEffect, useRef } from "react";
import { isLoggedIn } from "scripts/jwt/isLoggedIn";
import { useHistory } from "react-router-dom";

export const PaymentGateways = () => {
  const loggedIn = useRef(isLoggedIn());
  const router = useHistory();
  useEffect(() => {
    if (!loggedIn.current) router.push("/login");
  }, [loggedIn, router]);

  return loggedIn.current ? (
    <div className="grid grid-cols-7">
      <div className="col-span-1">
        <SideNav />
      </div>

      <div className="col-span-6">
        <div className="w-4/5 h-screen mx-auto overflow-y-auto">
          <Table />
        </div>
      </div>
    </div>
  ) : null;
};
