import { IconButton } from "@material-ui/core";
import axios, { AxiosError } from "axios";
import { notify } from "scripts/notify";

import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import ToggleOffIcon from "@material-ui/icons/ToggleOffSharp";
import { useRecoilState } from "recoil";
import { userState } from "pages/account/content/atoms/user";
import { IAccount, User } from "shared/interfaces/user";
import { getAuthorization } from "scripts/jwt/getAuthorization";

const Active = ({
  accountId,
  status,
}: {
  accountId: string;
  status: "ACTIVE" | "INACTIVE";
}) => {
  const [user, setUser] = useRecoilState(userState);
  const handleSubmit: () => void = async () => {
    const api = process.env.REACT_APP_API;
    const newState = status === "INACTIVE" ? "ACTIVE" : "INACTIVE";
    let response;
    try {
      response = await axios.patch(
        `${api}/account/status`,
        {
          accountId: accountId,
          status: newState,
        },
        {
          headers: {
            Authorization: getAuthorization(),
          },
        }
      );

      if (response.status === 200) {
        notify(
          "success",
          `Account Status updated as ${
            status === "ACTIVE" ? "Inactive" : "active"
          }`
        );

        if (user) {
          const updatedAccounts: IAccount[] = user.Accounts.map((acc) => {
            if (acc.id === accountId) {
              return {
                id: acc.id,
                account: acc.account,
                userId: acc.userId,
                status: newState,
              };
            }
            return acc;
          });

          const updatedUser: User = { ...user, Accounts: updatedAccounts };
          setUser(updatedUser);

          console.log("UPDATE", updatedAccounts);
        }
      }
    } catch (error) {
      const e = error as AxiosError;
      if (!e.isAxiosError) {
        notify("error", "Something Went Wrong!");
        return;
      }

      const response = e.response;
      if (response?.status === 403) {
        notify("error", response.data.message);
        return;
      }
      //for general exceptions
      notify("error", "Something Went Wrong!");
      return;
    }
  };

  return (
    <div className="flex bg-gray-400 rounded-md ">
      <IconButton onClick={handleSubmit}>
        {status === "ACTIVE" ? (
          <ToggleOnIcon style={{ color: "#51ff03" }} />
        ) : (
          <ToggleOffIcon color="disabled" />
        )}
      </IconButton>
    </div>
  );
};

export { Active };
