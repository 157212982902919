import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useRecoilState, useSetRecoilState } from "recoil";
import { accountNumber } from "../atoms/accountNumber";
import { validationError } from "../atoms/validationError";
import { editModalState } from "../selector/editModalState";
import { EditModal } from "./modal";

const Edit = ({ accountId, value }: { accountId: string; value: string }) => {
  const [isOpen, setIsOpen] = useRecoilState(editModalState);
  const setValue = useSetRecoilState(accountNumber);
  const setError = useSetRecoilState(validationError);

  const handleClose = () => {
    setValue("");
    setError("");
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <div className="flex bg-gray-400 rounded-md">
      <Tooltip title="Edit">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <EditModal
        isOpen={isOpen as boolean}
        handleClose={handleClose}
        accountId={accountId}
        value={value}
      />
    </div>
  );
};

export { Edit };
