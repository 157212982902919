import TextField from "@material-ui/core/TextField";
import { useRecoilState } from "recoil";
import { searchTermState } from "../atoms/searchTerm";
import { KycUsers } from "../atoms/user";
import { useGetUsers } from "./hooks/useGetUsers";

export const Search = () => {
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermState);

  useGetUsers(searchTerm);

  const setUsers = useRecoilState(KycUsers)[1];

  return (
    <div className="py-3">
      <TextField
        label="Enter Email"
        placeholder="someone@example.com"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.currentTarget.value);

          // Reset users on search
          setUsers([]);
        }}
      />
    </div>
  );
};
