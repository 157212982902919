import styles from "./styles/table.module.scss";
import { Tbody } from "./tbody";
import { Helpers } from "./helpers";

export const Table = () => {
  return (
    <>
      <table className="table table-fixed w-full border">
        <thead>
          <tr className="text-left bg-white ">
            <th className={styles.tableCell}>Name</th>
            <th className={styles.tableCell}>Email</th>
            <th className={styles.tableCell}>Status</th>
            <th className={styles.tableCell}>Actions</th>
          </tr>
        </thead>
        <Tbody />
      </table>

      <Helpers />
    </>
  );
};
