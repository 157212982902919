import { useEffect, useRef } from "react";

import { Content } from "./content";
import { SideNav } from "shared/sideNav";
import { decodeJwt } from "scripts/jwt/decodeJwt";
import { isLoggedIn } from "../../scripts/jwt/isLoggedIn";
import { useHistory } from "react-router-dom";

export const KycPage = () => {
  const loggedIn = useRef(isLoggedIn());
  const router = useHistory();

  const decodedData = decodeJwt();

  useEffect(() => {
    if (!loggedIn.current) router.push("/login");
  }, [loggedIn, router]);

  return loggedIn.current && decodedData ? (
    <>
      <div className="grid grid-cols-7">
        <div className="col-span-1">
          <SideNav />
        </div>

        <div className="col-span-6">
          <div className="w-4/5 h-screen mx-auto overflow-y-auto">
            {decodedData.role === "admin" ||
            decodedData.role === "retentionAgent" ? (
              <Content />
            ) : (
              <>You do not have enough permissions to access this feature</>
            )}
          </div>
        </div>
      </div>
    </>
  ) : null;
};
